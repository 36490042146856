<template>
  <div v-if="loading" class="offline">
    <Loading />
  </div>
  <div v-else class="container">
    <div v-if="successfulModal" class="sucMdal">
      <div class="cc">
        <p class="top">Application Received!</p>
        <p>
          Please ensure your signed documents have all been downloaded. <br />
          <br />
          If they haven't been downloaded,
          <strong>Kindly stay on the page </strong> or
          <strong>accept the "allow multiple downloads"</strong> prompt on your
          browser if you haven't done that
        </p>
        <router-link to="/">
          <button>Done</button>
        </router-link>
      </div>
    </div>
    <div class="cloudLoading" v-if="uploading">
      <div>
        <p>Uploading your file, please wait...</p>
      </div>
      <Loading />
    </div>
    <div class="cloudLoading" v-else-if="getting">
      <div>
        <p>Getting your details, please wait...</p>
      </div>
      <Loading />
    </div>
    <div class="cloudLoading" v-else-if="lodd">
      <div>
        <p>Processing, please wait...</p>
      </div>
      <Loading />
    </div>
    <div class="w-100">
      <p class="top">Kindly fill this form to proceed with your transaction.</p>
      <form @keyup="checkForm">
        <div class="row">
          <div class="col-md-4">
            <label for=""
              >Sterling Account Number (NUBAN) <span class="imp">*</span>
            </label>
            <input
              type="number"
              name=""
              id=""
              placeholder="Sterling Account Number "
              class="form-control"
              v-model="pl.account_number"
              @input="checkNuban"
              maxlength="10"
              required
            />
          </div>
          <div class="col-md-4">
            <label for="">Name</label>
            <input
              type="text"
              readonly
              name=""
              id=""
              :value="pl.full_name"
              placeholder=""
              class="form-control"
            />
          </div>
          <div class="col-md-4">
            <label for="">Email Address</label>
            <input
              type="text"
              readonly
              name=""
              id=""
              :value="userEmail"
              class="form-control"
              placeholder="Email Address"
            />
          </div>
          <!-- <div class="col-md-4">
            <label for="">SBU</label>
            <input
              type="text"
              readonly
              name=""
              id=""
              value="T 20"
              class="form-control"
              placeholder="SBU"
            />
          </div> -->
          <!-- <div class="col-md-4">
            <label for="">Date of account opening</label>
            <input
              type="text"
              readonly
              name=""
              id=""
              value="T 20"
              class="form-control"
              placeholder="Date of account opening"
            />
          </div> -->
          <!-- <div class="col-md-4">
            <label for="">Branch Code</label>
            <input
              type="text"
              readonly
              name=""
              id=""
              value="T 20"
              class="form-control"
              placeholder="Branch Code"
            />
          </div> -->
          <!-- <div class="col-md-4">
            <label for="">Account Officer</label>
            <input
              type="text"
              readonly
              name=""
              id=""
              value="T 20"
              class="form-control"
              placeholder="Account Officer"
            />
          </div> -->
          <!-- <div class="col-md-4">
            <label for="">BM</label>
            <input
              type="text"
              readonly
              name=""
              id=""
              value="T 20"
              class="form-control"
              placeholder="BM"
            />
          </div> -->
          <!-- <div class="col-md-4">
            <label for="">TRBE</label>
            <input
              type="text"
              readonly
              name=""
              id=""
              value="T 20"
              class="form-control"
              placeholder="TRBE"
            />
          </div> -->
          <!-- <div class="col-md-4">
            <label for="">RBE</label>
            <input
              type="text"
              readonly
              name=""
              id=""
              value="T 20"
              class="form-control"
              placeholder="RBE"
            />
          </div> -->

          <div class="col-md-4">
            <label for="">Phone Number</label>
            <input
              type="text"
              name=""
              id=""
              placeholder="Phone Number"
              :value="userData.user.phone_number"
              readonly
              class="form-control"
            />
          </div>
          <!-- <div class="col-md-4">
            <label for="">Customer's Address </label>
            <input
              type="text"
              name=""
              id=""
              placeholder="Address"
              class="form-control"
              value="T 20"
              readonly
            />
          </div> -->
          <div class="col-12">
            <div class="row">
              <div class="col-md-4">
                <label for="">Means of ID <span class="imp">*</span></label>
                <div class="w-100 inpMask">
                  <label style="display: block" for="ID">{{ idname }}</label>
                  <iframe v-if="idtyp === 'pdf'" class="frame" />
                  <img :src="prv" v-else class="frame" id="frame" />
                </div>
                <input
                  @change="showIdName($event, 'id')"
                  type="file"
                  name="ID"
                  id="ID"
                  class="hdn"
                  accept=".jpg, .jpeg, .pdf, .png, .webp"
                />
              </div>
              <div class="col-md-4">
                <label for=""
                  >Six months statement of account
                  <span class="imp">*</span></label
                >
                <div class="w-100 inpMask">
                  <label for="SOA">{{ stname }}</label>
                  <iframe v-if="sttyp === 'pdf'" class="frame" />
                  <img v-else :src="prvst" class="frame" />
                </div>
                <input
                  @change="showIdName($event, 'st')"
                  type="file"
                  name="SOA"
                  id="SOA"
                  class="hdn"
                  accept=".jpg, .jpeg, .pdf, .png, .webp"
                />
              </div>
              <div class="col-md-4">
                <label for=""
                  >Employment Status <span class="imp">*</span></label
                >
                <div class="w-100 inpMask">
                  <label for="employment">{{ emname }}</label>
                  <iframe v-if="emtyp === 'pdf'" class="frame" />
                  <img :src="prvem" v-else class="frame" />
                </div>
                <input
                  @change="showIdName($event, 'em')"
                  type="file"
                  name="employment"
                  id="employment"
                  class="hdn"
                  accept=".jpg, .jpeg, .pdf, .png, .webp"
                />
              </div>
              <div class="col-md-4">
                <label for="">Signature <span class="imp">*</span></label>
                <div class="w-100 inpMask">
                  <label for="sign">{{ sgname }}</label>
                  <img :src="prvsg" class="frame" />
                </div>
                <input
                  @change="showIdName($event, 'sg')"
                  type="file"
                  name="sign"
                  id="sign"
                  class="hdn"
                  accept=".jpg, .jpeg, .png, .webp"
                />
              </div>
            </div>
          </div>
          <!-- <div class="col-md-4">
            <label for="">Sterling Branch <span class="imp">*</span></label>
            <input
              type="text"
              name=""
              id=""
              v-model="branch"
              placeholder="Sterling Branch"
              class="form-control"
              required
            />
          </div> -->
          <div class="col-md-4">
            <label>Cost of Product(s)</label>
            <input
              type="text"
              readonly
              name=""
              id=""
              :value="'₦ ' + numberFormat(subtotal)"
              placeholder="Cost of Products"
              class="form-control"
            />
          </div>
          <div class="col-md-4">
            <label>Total Cost </label>
            <input
              type="text"
              readonly
              name=""
              id=""
              :value="'₦ ' + numberFormat(total)"
              placeholder="Cost of Products"
              class="form-control"
            />
          </div>
          <div class="col-md-4">
            <label for=""
              >Equity Contribution (%) <span class="imp">*</span></label
            >
            <sub>Minimum equity contribution is 30%</sub>
            <input
              type="number"
              name=""
              id=""
              min="30"
              v-model="pl.equity"
              placeholder="Equity Amount"
              class="form-control"
              oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
              maxlength="2"
              required
              @change="setEquity"
              @blur="setEquity"
            />
          </div>
          <div class="col-md-4">
            <label>Loan Size</label>
            <input
              type="text"
              readonly
              name=""
              id=""
              :value="'₦ ' + numberFormat(loan)"
              placeholder="Loan Amount"
              class="form-control"
            />
          </div>
          <div class="col-12">
            <p>Please confirm these are the items in your cart:</p>
            <div class="row">
              <div class="col-md-4" v-for="(item, index) in cart" :key="index">
                <div class="citm">
                  <div class="nme">
                    <h5>{{ item.product.name }}</h5>
                  </div>
                  <div class="prc">
                    <h6>
                      ₦ {{ numberFormat(item.product.price) }} x
                      {{ item.quantity }}
                    </h6>
                  </div>
                </div>
              </div>
              <!-- <div class="col-12 mt-60">
                <div class="citm">
                  <div class="nme">
                    <h4>Delivery Fee</h4>
                  </div>
                  <div class="prc">
                    <h6>₦ {{ numberFormat(deliveryFee) }}</h6>
                  </div>
                </div>
              </div> -->
            </div>
          </div>
          <div class="col-12 concent">
            <input type="checkbox" v-model="aggreed" id="chh" />
            <label for="chh"
              >I agree that all item(s) listed here are the same items I added
              to my cart.</label
            >
          </div>
          <div class="col-12 frm" v-if="showLetters">
            <letter-of-application
              :uname="pl.full_name"
              :sign="sign"
              :amt="'₦' + numberFormat(loan) + 'K'"
              :adr1="pl.customer_address"
              :download="signed"
              :show="signed"
            />
            <!-- :adr2="ad2" -->
          </div>
          <!-- <div class="col-12">
            <input type="checkbox" v-model="signed" v-if="!signed" /> Sign all
          </div> -->
          <div class="col-12 forc">
            <button
              @click.prevent="Submit"
              :disabled="!showLetters"
              class="sbtn"
            >
              Sign all and submit
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import Loading from "@/components/Loading.vue";
import LetterOfApplication from "@/components/RcLetters/LetterOfApplication.vue";
export default {
  components: { Loading, LetterOfApplication },
  data() {
    return {
      uploading: false,
      getting: false,
      sign: "",
      successfulModal: false,
      pl: {
        full_name: "",
        bvn: "",
        email: "",
        total_cost_product: "",
        loan_size: "",
        customer_address: "",
        sbu: "",
        date_acount_opening: "",
        branch_code: "",
        account_officer: "",
        bm: "",
        rbe: "",
        id_document: "",
        statement_account: "",
        employment_status: "",
        signature_scan: "",
        account_number: "",
        equity: "",
        status: "pending",
        rejection_reason: "",
        phone_number: "",
      },
      idname: "Choose File",
      prv: "",
      idtyp: "img",
      stname: "Choose File",
      prvst: "",
      sttyp: "img",
      emname: "Choose File",
      prvem: "",
      emtyp: "img",
      sgname: "Choose File",
      prvsg: "",
      ad1: "",
      ad2: "",
      // usrname: "User Name",
      // usrmail: "User@mail.com",
      // phone_number: "",
      loading: true,
      lodd: false,
      // userData.customer: {
      //   first_name: "",
      //   last_name: "",
      //   email: "",
      //   phone_number: "",
      // },
      subtotal: 0,
      deliveryFee: 0,
      cart: [],
      total: 0,
      aggreed: false,
      completed: false,

      // address: "",
      branch: "",
      // .pl.account_number: "",
      paymentSummary: {},
      signed: false,

      // loan: 0 ,
    };
  },
  computed: {
    // sharedkeyval() {
    //   return this.Util.binaryToAscii(`${process.env.VUE_APP_SHARED_KEY_VAL}`);
    // },
    // sharedvectorval() {
    //   return this.Util.binaryToAscii(
    //     `${process.env.VUE_APP_SHARED_VECTOR_VAL}`
    //   );
    // },
    loan() {
      // if (this.pl.equity > this.total) {
      //   this.pl.equity = "";
      //   return this.total;
      // } else {
      //   return this.total - this.pl.equity;
      // }
      let perc = this.pl.equity / 100;
      let dis = perc * this.total;
      // console.log(perc);
      // console.log(dis);
      return (this.total - dis).toFixed(2);
    },
    userData() {
      if (localStorage.getItem("user")) {
        let user = this.CryptoJS.AES.decrypt(
          localStorage.getItem("user"),
          this.$passPhrase
        ).toString(this.CryptoJS.enc.Utf8);
        // console.log(JSON.parse(user));
        return JSON.parse(user);
      } else {
        return false;
      }
    },
    userEmail() {
      return this.userData.user.email;
    },
    address() {
      return (
        this.userData.customer.address.street +
        " " +
        this.userData.customer.address.lga +
        " " +
        this.userData.customer.address.state
      );
    },
    showLetters() {
      return (
        this.pl.account_number.length === 10 &&
        this.pl.signature_scan &&
        this.pl.full_name &&
        this.pl.total_cost_product &&
        this.pl.branch_code &&
        // this.pl.id_document &&
        // this.pl.statement_account &&
        // this.employment_status &&
        this.completed &&
        this.pl.equity &&
        this.pl.equity >= 30 &&
        this.aggreed
      );
    },
  },
  watch: {
    subtotal() {
      this.pl.total_cost_product = this.subtotal;
    },
  },
  mounted() {
    // console.log(process.env.VUE_APP_AP_ID);
    // console.log(this.encrypt(this.sharedkeyval, this.sharedvectorval, "Hello"));

    // console.log(
    //   this.decrypt(
    //     this.sharedkeyval,
    //     this.sharedvectorval,
    //     this.encrypt(this.sharedkeyval, this.sharedvectorval, "Hello")
    //   )
    // );
    // this.pl.email = this.userEmail;
    // var exist = localStorage.getItem("identifier");
    var storedcart = this.$store.getters.cartProducts;
    // console.log(this.userData);
    // console.log(storedcart);
    // console.log(this.userData);
    // console.log(storedcart);
    // console.log(storedcart.length);

    if (storedcart.length < 1) {
      this.$router.push("/categories");
    } else {
      // this.cart = this.$store.getters.cartProducts;
      // var amount = 0;
      // var delivery = 0;
      // this.cart.forEach((item) => {
      //   var unitprice = 0;
      //   unitprice = item.product.price * parseInt(item.quantity);
      //   amount += unitprice;
      //   delivery += item.product.delivery_cost;
      //   // console.log(item.product.price);
      //   // console.log(amount);
      //   this.subtotal = amount;
      //   this.deliveryFee = delivery;

      //   this.total = this.subtotal;
      // });
      let payload = {
        path: `cart`,
      };
      this.$store
        .dispatch("getRequest", payload)
        .then((resp) => {
          let decr = JSON.parse(this.$helpers.decrypt(resp.data.data));
          resp.data = decr;
          this.loading = false;
          this.paymentSummary = resp.data.data;
          this.cart = this.paymentSummary.cart.items;

          // if (this.paymentSummary.total_price < 2000000) {
          //   this.$router.push("/checkout");
          // }
          // if (this.paymentSummary.sub_total < 2000000) {
          //   this.$router.push("/cart");
          // }

          // console.log(this.paymentSummary);

          var amount = 0;
          var delivery = 0;

          this.paymentSummary.cart.items.forEach((item) => {
            var unitprice = 0;
            unitprice = item.product.price * parseInt(item.quantity);
            amount += unitprice;
            delivery += item.product.delivery_cost;
            // console.log(item.product.price);
            // console.log(amount);
            this.subtotal = amount;
            this.deliveryFee = delivery;
            this.total = this.subtotal + this.deliveryFee;
          });
        })
        .catch((err) => {
          this.paymentSummary = null;
          if (err.response) {
            this.$toast.info(
              "Take a Loan",
              err.response.data.message,
              this.$toastPosition
            );
          } else {
            this.$toast.info(
              "Take a Loan",
              "Unable to get payment summary, please try again",
              this.$toastPosition
            );
          }
          this.loading = false;
          this.$router.push("/");
        });
    }

    // this.loan = this.total - this.pl.equity
    // console.log(this.pl.equity);
    // console.log(this.loan);
  },
  methods: {
    checkNuban() {
      if (this.pl.account_number.length > 10) {
        this.pl.account_number = this.pl.account_number.slice(0, 10);
      } else if (this.pl.account_number.length === 10) {
        this.getT24();
      }
    },
    getT24() {
      // 0070019075
      // if(){}
      // console.log(this.pl.account_number);
      this.getting = true;
      let payload = {
        path: `credit-portal/get-account/${this.pl.account_number}`,
      };

      // fetch(
      //   `https://imperiumbe-testing.sterlingapps.p.azurewebsites.net/v1/credit-portal/get-account/${this.pl.account_number}`,
      //   {
      //     method: "GET",
      //   }
      // ).then((resp) => resp.json())
      // .then(data => {
      //   console.log(data);
      // })

      this.$store
        .dispatch("getRequest", payload)
        .then((resp) => {
          let decr = JSON.parse(this.$helpers.decrypt(resp.data.data));
          resp.data = decr;
          // console.log(resp);
          if (
            resp.data.data.BankAccountFullInfo.NUBAN === this.pl.account_number
          ) {
            var act = resp.data.data.BankAccountFullInfo;
            // console.log(act);
            this.getting = false;
            this.pl.full_name = act.CUS_SHO_NAME;
            this.pl.email = act.email;
            this.pl.phone_number = act.MOB_NUM;
            this.pl.customer_address = act.ADD_LINE1 + ", " + act.ADD_LINE2;
            this.pl.bvn = act.BVN;
            this.ad1 = act.ADD_LINE1;
            this.ad2 = act.ADD_LINE2;
            this.pl.date_acount_opening = act.DATE_OPEN;
            this.pl.branch_code = act.BRA_CODE;
          } else {
            this.$toast.error(
              "Account Number",
              "Invalid account number",
              this.$toastPosition
            );
            this.pl.account_number = "";
            this.getting = false;
          }
        })
        .catch((err) => {
          // console.log(err);
          this.$toast.error(
            "Account Number",
            "Invalid account number",
            this.$toastPosition
          );
          this.pl.account_number = "";
          this.getting = false;
        });
    },
    setEquity() {
      if (this.pl.equity < 30) {
        this.pl.equity = 30;
      }
    },

    showIdName(e, inp) {
      var type = e.target.files[0].type.toLowerCase();
      // console.log(e.target.files);
      // console.log(e.target.value);
      if (inp !== "sg") {
        if (
          type !== "image/jpeg" &&
          type !== "image/jpg" &&
          type !== "image/png" &&
          type !== "image/webp" &&
          type !== "application/pdf"
        ) {
          this.$toast.error(
            "File Upload",
            "Invalid File type",
            this.$toastPosition
          );
          e.target.value = "";
        } else {
          this.uploading = true;
          var ufile = e.target.files[0];
          var file = new FormData();
          console.log(file);
          file.append("file", ufile);
          console.log(file);
          let payload = {
            data: file,
            path: "/uploads/get-url",
          };
            console.log(payload);
          this.$store
            .dispatch("postRequest", payload)
            .then((resp) => {
              // console.log(resp);
              let decr = JSON.parse(this.$helpers.decrypt(resp.data.data));
              resp = decr;
              // console.log(resp);
              if (resp.status === "success") {
                if (inp === "id") {
                  if (type === "application/pdf") {
                    this.idtyp = "pdf";
                  } else {
                    this.idtyp = "img";
                  }
                  this.idname = e.target.files[0].name;
                  this.prv = resp.data;
                  this.pl.id_document = resp.data;
                } else if (inp === "st") {
                  if (type === "application/pdf") {
                    this.sttyp = "pdf";
                  } else {
                    this.sttyp = "img";
                  }
                  this.stname = e.target.files[0].name;
                  this.prvst = resp.data;
                  this.pl.statement_account = resp.data;
                } else if (inp === "em") {
                  if (type === "application/pdf") {
                    this.emtyp = "pdf";
                  } else {
                    this.emtyp = "img";
                  }
                  this.emname = e.target.files[0].name;
                  this.prvem = resp.data;
                  this.pl.employment_status = resp.data;
                }
                this.$toast.success(
                  "File Upload",
                  "File Uploaded Successfully!",
                  this.$toastPosition
                );
                this.uploading = false;
              }
            })
            .catch((err) => {
              console.log(err);
              this.$toast.error(
                "File Upload",
                "There was an error uploading your file",
                this.$toastPosition
              );
            });

          // data.append("upload_preset", prst);

          // axios
          //   .post(cloudUrl, data, {
          //     headers: { "Content-Type": "application/x-www-form-urlencoded" },
          //   })
          //   .then((resp) => {
          //     // console.log(resp);
          //     if (inp === "id") {
          //       if (type === "application/pdf") {
          //         this.idtyp = "pdf";
          //       } else {
          //         this.idtyp = "img";
          //       }
          //       this.idname = e.target.files[0].name;
          //       this.prv = resp.data.secure_url;
          //       this.pl.id_document = resp.data.secure_url;
          //     } else if (inp === "st") {
          //       if (type === "application/pdf") {
          //         this.sttyp = "pdf";
          //       } else {
          //         this.sttyp = "img";
          //       }
          //       this.stname = e.target.files[0].name;
          //       this.prvst = resp.data.secure_url;
          //       this.pl.statement_account = resp.data.secure_url;
          //     } else if (inp === "em") {
          //       if (type === "application/pdf") {
          //         this.emtyp = "pdf";
          //       } else {
          //         this.emtyp = "img";
          //       }
          //       this.emname = e.target.files[0].name;
          //       this.prvem = resp.data.secure_url;
          //       this.pl.employment_status = resp.data.secure_url;
          //     }
          //     this.$toast.success(
          //       "File Upload",
          //       "File Uploaded Successfully!",
          //       this.$toastPosition
          //     );
          //     this.uploading = false;
          //   })
          //   .catch((err) => {
          //     this.$toast.success(
          //       "File Upload",
          //       "An error occured when uploading the file",
          //       this.$toastPosition
          //     );
          //     console.log(err);
          //   });

          // this.prv = ;
        }
      } else {
        if (
          type !== "image/jpeg" &&
          type !== "image/jpg" &&
          type !== "image/png" &&
          type !== "image/webp"
        ) {
          this.$toast.error(
            "File Upload",
            "Invalid File type",
            this.$toastPosition
          );
          e.target.value = "";
          this.sign = "";
        } else {
          this.uploading = true;
          var ufile = e.target.files[0];
          var file = new FormData();
          file.append("file", ufile);
          // data.append("upload_preset", prst);

          // axios
          //   .post(cloudUrl, data, {
          //     headers: { "Content-Type": "application/x-www-form-urlencoded" },
          //   })
          let payload = {
            data: file,
            path: "/uploads/get-url",
          };
          this.$store
            .dispatch("postRequest", payload)
            .then((resp) => {
              this.sign = URL.createObjectURL(ufile);
              // console.log(resp);
              let decr = JSON.parse(this.$helpers.decrypt(resp.data.data));
              resp = decr;
              this.sgname = e.target.files[0].name;
              this.prvsg = resp.data;
              this.pl.signature_scan = resp.data;
              this.$toast.success(
                "File Upload",
                "File Uploaded Successfully!",
                this.$toastPosition
              );
              this.uploading = false;
            })
            .catch((err) => {
              this.$toast.error(
                "File Upload",
                "There was an error uploading your signature",
                this.$toastPosition
              );
              console.log(err);
              this.sign = "";
            });

          // this.prv = ;
        }
      }
      // setTimeout(() => {
      //   console.log(e.target.files[0].name);
      //   // console.log();

      //   console.log(e);
      // }, 5000);
    },
    numberFormat(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    checkForm() {
      // console.log("checking");
      this.completed = false;
      // console.log(this.address);

      // if (this.address.length > 7) {
      //   var addr = true;
      //   // console.log("Address");
      // } else {
      //   var addr = false;
      //   // console.log("!Address");
      // }
      if (this.pl.account_number.length === 10) {
        var acct = true;
        // console.log("Number");
      } else {
        var acct = false;
        // console.log("!Number");
      }
      // if (this.branch.length > 3) {
      //   // console.log("Branch");
      //   var br = true;
      // } else {
      //   // console.log("!Branch");
      //   var br = false;
      // }
      // if (this.total > 100) {
      //   // console.log("Tott");
      //   var tot = true;
      // } else {
      //   // console.log("!Tott");
      //   var tot = false;
      // }

      if (acct) {
        this.completed = true;
        // console.log("completed");
      } else {
        this.completed = false;
        // console.log("ish");
      }
    },
    Submit() {
      if (this.showLetters) {
        if (this.$store.getters.cartProducts.length) {
          var productArr = [];

          // console.log(this.$store.getters.cartProducts);

          this.$store.getters.cartProducts.forEach((item) => {
            var obj = {};

            obj.quantity = parseInt(item.quantity);
            obj.name = item.product.name;
            obj.price = item.product.price;
            // obj.delivery_fee = item.product.delivery_cost;
            productArr.push(obj);
            // console.log(item);
          });
          // console.log(productArr);
          this.lodd = true;
          // let data = {
          //   first_name: this.userData.customer.first_name,
          //   last_name: this.userData.customer.last_name,
          //   phone_number: this.userData.user.phone_number,
          //   email: this.userData.user.email,
          //   home_address: this.address,
          //   bank_account: this.pl.account_number,
          //   bank_branch: this.branch,
          //   pl.equity_amount: this.pl.equity ? parseFloat(this.pl.equity) : 0,
          //   total_price: parseFloat(this.total),
          //   loan_amount: this.loan,
          //   product: productArr,
          //   // invite_token: localStorage.getItem("identifier"),
          // };
          // let payload = {
          //   path: `/offline`,
          //   data,
          // };
          // console.log(payload);
          // this.$store
          //   .dispatch("postRequest", payload)
          //   .then((resp) => {
          //     console.log(resp);
          //     if (resp) {
          //       if (resp.status === 200 && resp.data.status === "success") {
          //         this.$toast.success(
          //           "Offline Transaction",
          //           "Details submitted. We'll revert to you with the status of the transaction",
          //           this.$toastPosition
          //         );
          //         // this.$router.push("/");
          //         this.$store.commit("updateCart", []);
          //         setTimeout(() => {
          //           this.$router.push("/");
          //         }, 1000);
          //       } else {
          //         this.$toast.error(
          //           "Offline Transaction",
          //           "Something went wrong.",
          //           this.$toastPosition
          //         );
          //         // setTimeout(() => {
          //         //   this.$router.push("/");
          //         // }, 1000);
          //       }
          //     } else {
          //       this.$toast.error(
          //         "Offline Transaction",
          //         "Something went wrong",
          //         this.$toastPosition
          //       );
          //       // setTimeout(() => {
          //       //   this.$router.push("/");
          //       // }, 1000);
          //     }
          //   })
          //   .catch((err) => {
          //     // console.log(err);
          //     this.$toast.error(
          //       "Offline Transaction",
          //       "Something went wrong",
          //       this.$toastPosition
          //     );
          //     // localStorage.removeItem("identifier");
          //     // setTimeout(() => {
          //     //   this.$router.push("/");
          //     // }, 1000);
          //   });

          // setTimeout(() => {
          this.$toast.info(
            "Sign and submit",
            "Processing",
            this.$toastPosition
          );
          this.pl.product = productArr;
          this.pl.loan_size = this.loan;
          // console.log(this.pl);
          let data = this.pl;
          // console.log(data);
          data = this.$helpers.encrypt(JSON.stringify(data));

          data = {
            data,
          };
          let payload = {
            data,
            path: "/credit-portal",
          };
          // console.log(payload);
          this.$store
            .dispatch("postRequest", payload)
            .then((resp) => {
              let decr = JSON.parse(this.$helpers.decrypt(resp.data.data));
              resp.data = decr;
              console.log(resp);
              if (resp.status === 200) {
                this.$toast.info(
                  "Sign and submit",
                  "Downloading signed documents",
                  this.$toastPosition
                );
                this.signed = true;
                this.successfulModal = true;
                this.lodd = false;
                this.$store.commit("updateCart", []);
                this.$toast.success(
                  "Sign and submit",
                  "Application received, Our representative will get back to you",
                  this.$toastPosition
                );
              } else {
                this.$toast.error(
                  "Sign and submit",
                  "Something went wrong, please try again",
                  this.$toastPosition
                );
                this.lodd = false;
              }
              // console.log(resp);
            })
            .catch((err) => {
              this.lodd = false;
              if (err.response) {
                console.log(err.response);
                let decr = JSON.parse(
                  this.$helpers.decrypt(err.response.data.data)
                );
                // resp.data = decr;
                this.$toast.error(
                  "Sign and submit",
                  decr.message,
                  this.$toastPosition
                );
              } else {
                this.$toast.error(
                  "Sign and submit",
                  "Something went wrong, Please check your internet connection",
                  this.$toastPosition
                );
              }
            });
          // }, 2000);
        } else {
          this.$toast.error(
            "Offline Transaction",
            "Please add products to cart",
            this.$toastPosition
          );
          this.$router.push("/categories");
        }
      } else {
        this.$toast.error(
          "Offline Transaction",
          "Please fill all details",
          this.$toastPosition
        );
      }

      // this.$toast.info("Offline Transaction", "Posting", this.$toastPosition);
    },
  },
};
</script>

<style scoped>
.frame {
  width: 100%;
  border: none;
  overflow: hidden;
}
.frame::-webkit-scrollbar {
  display: none;
  /* width: 1px; */
}
iframe img {
  width: 100%;
}
.hdn {
  display: none;
}
.inpMask {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
  height: 200px;
  padding: 0.625rem 0.75rem;
  font-weight: 400;
  line-height: 1.5;
  color: #8898aa;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #cad1d7;
  border-radius: 0.25rem;
  box-shadow: none;
  transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}
.inpMask label {
  cursor: pointer;
}
.offline {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999999999999999999;
}
.imp {
  color: red;
}
.mt-60 {
  margin-top: 24px;
}
input#chh {
  margin-right: 5px;
}
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
.forc {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 52px;
  margin-block: 32px;
}
.sbtn:disabled,
.sbtn:disabled:hover {
  background-color: #e9ecef;
  color: #fff;
  cursor: not-allowed;
}
.sbtn {
  border: none;
  background-color: #255e13;
  transition: all 0.3s ease-in-out;
  padding: 8px 24px;
  border-radius: 12px;
  color: #fff;
  margin: auto;
  display: inline-block;
  font-weight: 600;
}
.sbtn:hover {
  background-color: #fdd826;
  color: #525f7f;
}
.concent input,
.concent label {
  cursor: pointer;
}

.concent {
  margin-top: 24px;
}

p.top {
  margin-top: 56px;
  margin-bottom: 24px;
  text-align: center;
}
form {
  /* width: 80%; */
  margin: auto;
}
form .col-md-4 {
  margin-block: 15px;
}
.citm {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #255e13;
  border-top: none;
  border-bottom: none;
  /* border-radius: .25rem; */
  padding: 0 0.75rem;
}
.citm * {
  margin: 0;
}
.cloudLoading * {
  margin: 0;
  padding: 0;
}
.cloudLoading .text-center {
  margin: 0;
}
.cloudLoading {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 120vh;
  z-index: 99999;
  background-color: #ffffff88;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  overflow: hidden;
  backdrop-filter: blur(3px);
}
.frm {
  overflow-x: auto;
}
</style>

<style >
.cloudLoading .text-center {
  margin-top: 24px !important;
}
</style>
<style lang="scss" scoped>
.sucMdal {
  position: fixed;
  top: 0;
  left: 0;
  height: 110vh;
  width: 100%;
  background-color: #00000088;
  backdrop-filter: blur(5px);
  z-index: 99999;
  display: flex;
  align-items: center;
  justify-content: center;
  .cc {
    width: 300px;
    padding: 16px;
    background-color: #ffffff;
    border-radius: 16px;
    .top {
      font-weight: 600;
      font-size: 1.1rem;
      color: green;
      text-align: center;
      width: 100%;
      margin: 0;
      margin-bottom: 24px;
    }
    a {
      display: inline-block;
      width: 100%;
      text-align: center;
      button {
        text-align: center;
        border: none;
        background-color: #255e13;
        transition: all 0.3s ease-in-out;
        padding: 8px 24px;
        border-radius: 12px;
        color: #fff;
        margin: auto;
        display: inline-block;
        font-weight: 600;
        margin-top: 32px;
        &:hover {
          background-color: #fdd826;
          color: #525f7f;
        }
      }
    }
  }
}
</style>