<template>
  <div class="pdf">
    <VueHtml2pdf 
      :float-layout="true" :enable-download="true"  :preview-modal="false"  filename="Application Letter" :pdf-quality="2" :manual-pagination="true" pdf-format="a4" pdf-orientation="portrait" pdf-content-width="100%" ref="html2Pdf" 
    >
      <section slot="pdf-content">

    
          <div ref="appl" width="595px" height="842px" style="height: 842px; font-family: Arial, sans-serif !important; background-color: #fff; padding: 16px; color: #000;" class="letterOfApplication ech">
            <div style="width: 100%; display: flex; align-items: center; justify-content: flex-end;"  class="w-100">
              <img src="@/assets/images/sterling.webp" style="width: 120px" alt="">
            </div>
            <div  class="w-100">
              <div style="margin-bottom: 12px;" class="cadr">
                <p  style="font-family: Arial, sans-serif !important; margin: 0; width: 150px">{{ adr1 }},</p>
                <p  class="dt" style="font-family: Arial, sans-serif !important; margin-top: 12px;">{{ today }}</p>
              </div>
              <div class="badr">
                <p style="font-family: Arial, sans-serif !important;">
                  Sterling Bank, <br />
                  Imperium, <br />
                  20, Marina Road, <br />
                  Lagos.
                </p>
              </div>
              <div class="bod">
                <p style="font-family: Arial, sans-serif !important;">Dear Sterling Bank Team,</p>
                <p  class="w-100 tl" style="font-family: Arial, sans-serif !important; width: 100%; text-align: center; text-decoration: underline; font-weight: 700;">
                  Application for Loan for Renewable Energy Asset
                </p>
                <p style="font-family: Arial, sans-serif !important;" class="para">
                  I am writing this loan request letter to ask for a loan amounting to
                  <strong>{{ amt }}</strong> for the purpose of procuring Renewable
                  Energy Assets.
                </p>
                <p style="font-family: Arial, sans-serif !important;" class="para">
                  The Renewable Energy Asset is needed to power my home; this will
                  enhance my productivity and provide a more comfortable living.
                </p>
                <p style="font-family: Arial, sans-serif !important;" class="para">
                  Please see attached required documentation as requested.
                </p>
                <p style="font-family: Arial, sans-serif !important;" class="para">Thank you for your support, as always.</p>
                <p style="font-family: Arial, sans-serif !important;" class="bd"><strong>{{ uname }}</strong></p>
                <img  width= '120px' height='90px'  class="sign"  :src="sign" alt="" />
              </div>
            </div>
          </div>
      </section>
    </VueHtml2pdf>
   
          <div ref="appl" width="595px" height="842px" style="height: 842px; font-family: Arial, sans-serif !important; background-color: #fff; padding: 16px; color: #000;" class="letterOfApplication ech">
             <div style="width: 100%; display: flex; align-items: center; justify-content: flex-end;"  class="w-100">
              <img src="@/assets/images/sterling.webp" style="width: 120px" alt="">
            </div>
            <div  class="w-100">
              <div style="margin-bottom: 12px;" class="cadr">
                <p  style="font-family: Arial, sans-serif !important; margin: 0; width: 150px">{{ adr1 }},</p>
                <p class="dt" style="font-family: Arial, sans-serif !important; margin-top: 12px;">{{ today }}</p>
              </div>
              <div class="badr">
                <p style="font-family: Arial, sans-serif !important;">
                  Sterling Bank, <br />
                  Imperium, <br />
                  20, Marina Road, <br />
                  Lagos.
                </p>
              </div>
              <div class="bod">
                <p style="font-family: Arial, sans-serif !important;">Dear Sterling Bank Team,</p>
                <p  class="w-100 tl" style="font-family: Arial, sans-serif !important; width: 100%; text-align: center; text-decoration: underline; font-weight: 700;">
                  Application for Loan for Renewable Energy Asset
                </p>
                <p style="font-family: Arial, sans-serif !important;" class="para">
                  I am writing this loan request letter to ask for a loan amounting to
                  <strong>{{ amt }}</strong> for the purpose of procuring Renewable
                  Energy Assets.
                </p>
                <p style="font-family: Arial, sans-serif !important;" class="para">
                  The Renewable Energy Asset is needed to power my home; this will
                  enhance my productivity and provide a more comfortable living.
                </p>
                <p style="font-family: Arial, sans-serif !important;" class="para">
                  Please see attached required documentation as requested.
                </p>
                <p style="font-family: Arial, sans-serif !important;" class="para">Thank you for your support, as always.</p>
                <p style="font-family: Arial, sans-serif !important;" class="bd"><strong>{{ uname }}</strong></p>
                <img  width= '120px' height='90px' v-if="show"  class="sign" :src="sign" alt="" />
              </div>
            </div>
          </div>
      <VueHtml2pdf 
      :float-layout="true" :enable-download="true" :preview-modal="false"  filename="Statement of Consent to collect use and disclose credit report" :pdf-quality="2" :manual-pagination="true" pdf-format="a4" pdf-orientation="portrait" pdf-content-width="100%" ref="cons"
    >
      <section slot="pdf-content">
         <div  width="595px" height="842px" style="height: 842px; font-family: Arial, sans-serif !important; background-color: #fff; padding: 16px; color: #000;" class="Consent ech">
           <div style="width: 100%; display: flex; align-items: center; justify-content: flex-end;"  class="w-100">
              <img src="@/assets/images/sterling.webp" style="width: 120px" alt="">
            </div>
      <div class="w-100">
        <div style="margin-bottom: 12px;" class="cadr">
          <p  style="font-family: Arial, sans-serif !important; margin: 0; width: 150px;">{{ adr1 }},</p>
          
          <p class="dt" style="font-family: Arial, sans-serif !important; margin-top: 12px;">{{ today }}</p>
        </div>
        <div class="badr">
          <p style="font-family: Arial, sans-serif !important;">
            Sterling Bank, <br />
            Imperium, <br />
            20, Marina Road, <br />
            Lagos.
          </p>
        </div>
        <div class="bod">
          <p style="font-family: Arial, sans-serif !important;">Dear Sterling Bank Team,</p>
          <p  class="w-100 tl" style="font-family: Arial, sans-serif !important; width: 100%; text-align: center; text-decoration: underline; font-weight: 700;">
            Letter of Commitment to Repay the Principal and the Accrued Interest
            within the Tenor
          </p>
          <p style="font-family: Arial, sans-serif !important;" class="para">
            I am writing this loan request letter to ask for a loan amounting to
            <strong>{{ amt }}</strong> for the purpose of procuring Renewable
            Energy Assets.
          </p>
          <p style="font-family: Arial, sans-serif !important;" class="para">
            The Renewable Energy Asset is needed to power my home; this will
            enhance my productivity and provide a more comfortable living.
          </p>
          <p style="font-family: Arial, sans-serif !important;" class="para">
            Please see attached required documentation as requested.
          </p>
          <p style="font-family: Arial, sans-serif !important;" class="para">Thank you for your support, as always.</p>
          <p style="font-family: Arial, sans-serif !important;" class="bd"> <strong> {{ uname }} </strong></p>
          <img  width= '120px' height='90px'  class="sign" :src="sign" alt="" />
        </div>
      </div>
    </div>
      </section>
     </VueHtml2pdf>
     
    <div  width="595px" height="842px" style="height: 842px; font-family: Arial, sans-serif !important; background-color: #fff; padding: 16px; color: #000;" class="Consent ech">
       <div style="width: 100%; display: flex; align-items: center; justify-content: flex-end;"  class="w-100">
              <img src="@/assets/images/sterling.webp" style="width: 120px" alt="">
            </div>
      <div class="w-100">
        <div style="margin-bottom: 12px;" class="cadr">
          <p  style="font-family: Arial, sans-serif !important; margin: 0; width: 150px;">{{ adr1 }},</p>
          
          <p class="dt" style="font-family: Arial, sans-serif !important; margin-top: 12px;">{{ today }}</p>
        </div>
        <div class="badr">
          <p style="font-family: Arial, sans-serif !important;">
            Sterling Bank, <br />
            Imperium, <br />
            20, Marina Road, <br />
            Lagos.
          </p>
        </div>
        <div class="bod">
          <p style="font-family: Arial, sans-serif !important;" >Dear Sterling Bank Team,</p>
          <p  class="w-100 tl" style="font-family: Arial, sans-serif !important; width: 100%; text-align: center; text-decoration: underline; font-weight: 700;">
            Letter of Commitment to Repay the Principal and the Accrued Interest
            within the Tenor
          </p>
          <p style="font-family: Arial, sans-serif !important;" class="para">
            I am writing this loan request letter to ask for a loan amounting to
            <strong>{{ amt }}</strong> for the purpose of procuring Renewable
            Energy Assets.
          </p>
          <p style="font-family: Arial, sans-serif !important;" class="para">
            The Renewable Energy Asset is needed to power my home; this will
            enhance my productivity and provide a more comfortable living.
          </p>
          <p style="font-family: Arial, sans-serif !important;" class="para">
            Please see attached required documentation as requested.
          </p>
          <p style="font-family: Arial, sans-serif !important;" class="para">Thank you for your support, as always.</p>
          <p style="font-family: Arial, sans-serif !important;" class="bd"> <strong> {{ uname }} </strong></p>
          <img  width= '120px' height='90px' v-if="show" class="sign" :src="sign" alt="" />
        </div>
      </div>
    </div>
    <VueHtml2pdf 
      :float-layout="true" :enable-download="true" :preview-modal="false"  filename="Statement of commitment to repay the principal and the accrued interest with the tenor" :pdf-quality="2" :manual-pagination="true" pdf-format="a4" pdf-orientation="portrait" pdf-content-width="100%" ref="comm"
    >
      <section slot="pdf-content">
        <div  width="595px" height="842px" style="height: 842px; font-family: Arial, sans-serif !important; background-color: #fff; padding: 16px; color: #000;" class="Commitment ech">
           <div style="width: 100%; display: flex; align-items: center; justify-content: flex-end;"  class="w-100">
              <img src="@/assets/images/sterling.webp" style="width: 120px" alt="">
            </div>
      <div class="w-100">
        <div class="bod">
          <p style="font-family: Arial, sans-serif !important;" class="para">
            I {{ uname }}, hereby grant Sterling Bank and Imperium the
            permission to collect, use and disclose my credit information.
          </p>
          <p style="font-family: Arial, sans-serif !important;" class="para">
            The following information are presented as requested:
            <ul style="font-family: Arial, sans-serif !important;"> 
              <li style="font-family: Arial, sans-serif !important;">Bank Verification Number</li>
              <li style="font-family: Arial, sans-serif !important;">Date of Birth</li>
            </ul>
          </p>
          <p style="font-family: Arial, sans-serif !important;" class="para">
            I elect to electronically sign this statement of consent. Thank you,
          </p>
          <p style="font-family: Arial, sans-serif !important;" class="para">Thank you,</p>
          <p style="font-family: Arial, sans-serif !important;" class="bd"> <strong> {{ uname }} </strong> </p>
          <img width= '120px' height='90px'  class="sign" :src="sign" alt="" />
        </div>
      </div>
    </div>
      </section>
     </VueHtml2pdf>
    <div width="595px" height="842px" style="height: 842px; font-family: Arial, sans-serif !important; background-color: #fff; padding: 16px; color: #000;" class="Commitment ech">
       <div style="width: 100%; display: flex; align-items: center; justify-content: flex-end;"  class="w-100">
              <img src="@/assets/images/sterling.webp" style="width: 120px" alt="">
            </div>
      <div class="w-100">
        <div class="bod">
          <p style="font-family: Arial, sans-serif !important;" class="para">
            I {{ uname }}, hereby grant Sterling Bank and Imperium the
            permission to collect, use and disclose my credit information.
          </p>
          <p style="font-family: Arial, sans-serif !important;" class="para">
            The following information are presented as requested:
            <ul style="font-family: Arial, sans-serif !important;">
              <li style="font-family: Arial, sans-serif !important;">Bank Verification Number</li>
              <li style="font-family: Arial, sans-serif !important;">Date of Birth</li>
            </ul>
          </p>
          <p style="font-family: Arial, sans-serif !important;" class="para">
            I elect to electronically sign this statement of consent. Thank you,
          </p>
          <p style="font-family: Arial, sans-serif !important;" class="para">Thank you,</p>
          <p style="font-family: Arial, sans-serif !important;" class="bd"> <strong> {{ uname }} </strong> </p>
          <img width= '120px' height='90px' v-if="show" class="sign" :src="sign" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueHtml2pdf from "vue-html2pdf";
export default {
  props: ["uname", "sign", "show", "amt", "adr1", "adr2", "download"],
  components: {
    VueHtml2pdf,
  },
  computed: {
    today() {
      var date = new Date();
      var day = (date.getDate() < 10 ? "0" : "") + date.getDate();
      var month = (date.getMonth() + 1 < 10 ? "0" : "") + (date.getMonth() + 1);
      var year = date.getFullYear();
      return `${day}/${month}/${year}`;
    },
    // download() {
    //   return this.download;
    // },
  },
  watch: {
    download() {
      if (this.download === true) {
        // console.log("downloading");
        setTimeout(() => {
          this.$refs.html2Pdf.generatePdf();
          this.$refs.comm.generatePdf();
          this.$refs.cons.generatePdf();
          // html2pdf(this.$refs.cons, {
          //   margin: 2,
          //   filename:
          //     "Statement of Consent to collect use and disclose credit report.pdf",
          // });
          // html2pdf(this.$refs.comm, {
          //   margin: 2,
          //   filename:
          //     "Statement of commitment to repay the principal and the accrued interest with the tenor.pdf",
          // });
          this.$toast.info(
            "Document Downloads",
            "Please allow multiple downloads",
            this.$toastPosition
          );
        }, 800);
        // console.log("done");
      }
    },
  },
};
</script>

<style scoped lang='scss'>
.pdf {
  width: 595px;
  max-width: 595px;
  .ech {
    height: 842px;
    background-color: #fff;
    padding: 16px;
    color: #000;
    margin-top: 32px;
    margin-top: 32px;
    border: 1px solid #255e13;
    position: relative;
    .cadr {
      p {
        margin: 0;
      }
      .dt {
        margin-top: 12px;
      }
      margin-bottom: 32px;
    }
    /* .badr {
    }
    .bod {
    } */
    .bod {
      .tl {
        width: 100%;
        text-align: center;
        text-decoration: underline;
        font-weight: 700;
      }
    }
  }
  img.sign {
    width: 120px;
    height: 90px;
  }
  .bd {
    font-weight: 700;
  }
}
</style>